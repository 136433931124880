<template>
  <file-pond
    name="test"
    ref="pond"
    label-idle="<i class='nc-icon nc-camera-compact'></i><br>Upload"
    v-bind:allow-multiple="false"
    accepted-file-types="image/jpeg, image/jpg, image/png, image/gif"
    v-on:processfile="fileUploaded"
    v-on:removefile="fileRemoved"
  />
</template>
<script>
  import TempFileService from 'src/services/tempFile.service'
  import swal from 'sweetalert2'

  import vueFilePond, { setOptions } from 'vue-filepond'
  import 'filepond/dist/filepond.min.css';

  // Import image preview plugin styles
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  );

  setOptions({
    server: {
      process:(fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

        TempFileService.store(file, progressEvent => {
          progress(true, progressEvent.loaded, progressEvent.total)
        })
          .then(function (res) {
            load(res.data.id)
          })
          .catch(function (err) {
            var msg = 'Sorry, there was a problem uploading your image. '
            msg += 'Please try a different image and contact support if the problem persists.'
            swal.fire({
              title: 'Error',
              text: msg,
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          })
        
        // Should expose an abort method so the request can be cancelled
        return {
            abort: () => {
                // This function is entered if the user has tapped the cancel button
                // request.abort();

                // Let FilePond know the request has been cancelled
                abort()
            }
        };
      },
      revert: (uniqueFileId, load, error) => {
          
        // Should remove the earlier created temp file here
        // ...
        TempFileService.destroy(uniqueFileId)
          .then(function (res) {
            // Should call the load method when done, no parameters required
            load();
          })
          .catch(function (err) {
            // Can call the error method if something is wrong, should exit after
            error('error deleting file')
          })
      }
    }
  })

  export default {
    data () {
      return {
        // nothing
      }
    },
    methods: {
      async init () {
        
      },
      fileUploaded () {
        var files = this.$refs.pond.getFiles()
        if (files.length > 0) {
          this.$emit('tempFileIdUpdated', files[0].serverId)
        } else {
          this.$emit('tempFileIdUpdated', '')
        }
      },
      fileRemoved () {
        this.$emit('tempFileIdUpdated', '')
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>
.filepond--credits {
  display: none;
}
</style>
