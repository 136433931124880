import Api from 'src/apis/api'

class TempFileService {
  store (file, progressEventCallback) {
    const config = {
      onUploadProgress: progressEvent => progressEventCallback(progressEvent)
    }
    
    const data = new FormData()
    data.append('file', file)
    return Api.post('/api/temp-files', data, config)
  }

  destroy (fileId) {
    return Api({
      method: 'delete',
      url: '/api/temp-files',
      data: { id: fileId }
    })
  }
}

export default new TempFileService()
